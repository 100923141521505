import { pipe, uniqWith } from 'ramda';
import { sortAlphabetically } from '../../../utils';

export const getEOs = pipe(
  (data, prop, tenantIds) =>
    Array.from(data.entries())
      .filter((e) => (tenantIds?.length ? tenantIds.includes(e[0]) : true))
      .flatMap((e) =>
        e[1].flatMap((site) => ({
          tenantId: e[0],
          id: site.organizationalStructure[prop]?.id,
          label: site.organizationalStructure[prop]?.name
        }))
      )
      .filter((o) => !!o.label)
      .sort(sortAlphabetically('label')),
  uniqWith((a, b) => a.id === b.id)
);

const showEOElement = (entityList, entityName, siteItem) =>
  !entityList || !entityList.length || entityList.includes(siteItem[entityName]?.id);

export const getEOsWithDependency = (
  data,
  prop,
  tenantIds,
  { areaManager, concessionManagement }
) => {
  const entites = Array.from(data.entries())
    .filter((e) => (tenantIds?.length ? tenantIds.includes(e[0]) : true))
    .flatMap((e) =>
      e[1].flatMap((site) => {
        if (prop === 'areaManager') {
          return {
            tenantId: e[0],
            id: site.organizationalStructure[prop]?.id,
            label: site.organizationalStructure[prop]?.name
          };
        }
        if (
          prop === 'concessionManagement' &&
          showEOElement(areaManager, 'areaManager', site.organizationalStructure)
        ) {
          return {
            tenantId: e[0],
            id: site.organizationalStructure[prop]?.id,
            label: site.organizationalStructure[prop]?.name
          };
        }
        if (prop === 'concessionManagement') {
          return undefined;
        }

        const showAM = showEOElement(areaManager, 'areaManager', site.organizationalStructure);
        const showDC = showEOElement(
          concessionManagement,
          'concessionManagement',
          site.organizationalStructure
        );
        if (prop === 'zoneManagement' && showAM && showDC) {
          return {
            tenantId: e[0],
            id: site.organizationalStructure[prop]?.id,
            label: site.organizationalStructure[prop]?.name
          };
        }
        return undefined;
      })
    )
    .filter((o) => o && !!o.label)
    .sort(sortAlphabetically('label'));
  return uniqWith((a, b) => a.id === b.id, entites);
};

export const getSites = (allSites, tenantIds) =>
  Array.from(allSites.entries())
    .filter((e) => (tenantIds?.length ? tenantIds.includes(e[0]) : true))
    .flatMap((e) =>
      e[1].flatMap((site) => ({
        tenantId: e[0],
        id: site.id,
        label: site.name
      }))
    )
    .filter((o) => !!o.label)
    .sort(sortAlphabetically('label'));
