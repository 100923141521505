/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { FilterMultiSelect } from '@naui/core';
import { useUserContext, useFeatureFlagContext } from '../../../hooks';
import { filterTenantId, getFilterSingleValue } from '../helpers/filters';
import { getSites } from '../helpers/orgStructureOptions';

export const filterSites = (sites, allSites, areaManager, concessionManagement, zoneManagement) => {
  return sites.filter((site) => {
    const siteData = allSites
      .get(site.tenantId)
      ?.find((s) => s.id === site.id)?.organizationalStructure;

    const siteAreaManager = siteData?.areaManager?.id;
    const siteConcessionManagement = siteData?.concessionManagement?.id;
    const siteZoneManagement = siteData?.zoneManagement?.id;

    const areaManagerMatch =
      isEmpty(areaManager) || (siteAreaManager && areaManager.includes(siteAreaManager));
    const concessionManagementMatch =
      isEmpty(concessionManagement) ||
      (siteConcessionManagement && concessionManagement.includes(siteConcessionManagement));
    const zoneManagementMatch =
      isEmpty(zoneManagement) ||
      (siteZoneManagement && zoneManagement.includes(siteZoneManagement));

    return areaManagerMatch && concessionManagementMatch && zoneManagementMatch;
  });
};

function FilterSiteSection({ filters, id, ...props }) {
  const { featureFlags } = useFeatureFlagContext();
  const { allSites } = useUserContext();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const tenantIds = filters?.find((f) => getFilterSingleValue(f) === filterTenantId)?.value;
  const currentFilter = filters?.find((f) => getFilterSingleValue(f) === id);
  const areaManager = filters?.find((f) => getFilterSingleValue(f) === 'areaManagersIds')?.value;
  const concessionManagement = filters?.find(
    (f) => getFilterSingleValue(f) === 'concessionManagementsIds'
  )?.value;
  const zoneManagement = filters?.find(
    (f) => getFilterSingleValue(f) === 'zoneManagementsIds'
  )?.value;

  useEffect(() => {
    const sites = getSites(allSites, tenantIds);
    let filteredSites = sites;

    filteredSites = filterSites(sites, allSites, areaManager, concessionManagement, zoneManagement);
    setOptions(filteredSites);

    if (currentFilter?.value) {
      const selectedValues = Array.isArray(currentFilter.value)
        ? currentFilter.value
        : [currentFilter.value];
      const validValues = filteredSites.map((site) => site.id);
      const updatedSelection = selectedValues.filter((value) => validValues.includes(value));

      if (updatedSelection.length !== selectedValues.length) {
        setTimeout(() => props.onChange(updatedSelection), 0);
      }
    }

    // if (window.location.pathname === '/alarms/analytics') {
    //   if (!isEmpty(areaManager) || !isEmpty(concessionManagement) || !isEmpty(zoneManagement)) {
    //     const allFilteredSiteIds = filteredSites.map((site) => site.id);
    //     props.onChange(allFilteredSiteIds);
    //   }

    //   if (isEmpty(areaManager) && isEmpty(concessionManagement) && isEmpty(zoneManagement)) {
    //     setTimeout(() => props.onChange([]), 0);
    //   }
    // }
  }, [
    tenantIds,
    areaManager,
    concessionManagement,
    zoneManagement,
    allSites,
    currentFilter?.value
  ]);

  const slicedData = useMemo(
    () =>
      options
        .filter((o) => !search || o.label.toLowerCase().includes(search.toLowerCase()))
        .map(({ id: value, label, ...v }) => ({
          ...v,
          value,
          label:
            featureFlags.usersEnabledDemo && currentFilter && currentFilter.anonymize
              ? currentFilter.anonymize(value || label)
              : label
        })),
    [search, options]
  );

  return (
    <FilterMultiSelect
      searchable
      {...props}
      data={slicedData}
      search={search}
      onSearch={setSearch}
    />
  );
}

FilterSiteSection.propTypes = {
  filters: PropTypes.arrayOf({
    id: PropTypes.string
  }).isRequired,
  id: PropTypes.string.isRequired
};

export default FilterSiteSection;
