import auth from '@acua/common/auth';
import { gatewayUrl } from '../config';

const importsUrl = `${gatewayUrl}/imports`;
const externalImportsUrl = `${gatewayUrl}/imports/external`;

const getImports = (body, url) => {
  let query = '';
  if (body && body.continuationToken) {
    query = `?continuationToken=${body.continuationToken}`;
  }
  return auth.authFetch(`${url}${query}`, {
    json: true,
    instanceId: body.instanceId
  });
};

const getInventoryImports = (body) => {
  return getImports(body, importsUrl);
};

const getInventoryExternalImports = (body) => {
  return getImports(body, externalImportsUrl);
};

const getImportDetails = (id, instanceId = null) =>
  auth.authFetch(`${importsUrl}/${id}/details`, {
    json: true,
    instanceId
  });

const getInventoryImport = (id, token, size, filterField, externalVisible) => {
  let queryParams = '';
  const qs = new URLSearchParams();

  if (token) {
    qs.set('continuationToken', token);
  }
  if (filterField && filterField !== '') {
    qs.set('filterField', filterField);
  }
  if (size && size !== '') {
    qs.set('size', size);
  }

  queryParams = qs.toString() ? `?${qs.toString()}` : '';
  return auth.authFetch(
    `${externalVisible ? externalImportsUrl : importsUrl}/${id}/rows${queryParams}`,
    {
      json: true
    }
  );
};

const getInventoryImportStatus = (id) =>
  auth.authFetch(`${importsUrl}/${id}/status`, {
    json: true
  });

const getInventoryImportStatusList = (body) =>
  auth.authFetch(`${importsUrl}/status`, {
    json: true,
    method: 'POST',
    body
  });

export default {
  getInventoryImports,
  getInventoryExternalImports,
  getInventoryImport,
  getImportDetails,
  getInventoryImportStatus,
  getInventoryImportStatusList
};
