export const productShortKeyToPath = {
  id: 'id',
  serialNumber: 'serialNumber',
  productType: 'productType',
  iotStatus: 'iotStatus',
  tenantId: 'tenantId',
  additionalKey: 'inventory.additionalKey',
  siteId: 'inventory.site.id',
  zoneManagementId: 'inventory.site.organizationalStructure.zoneManagement.id',
  concessionManagementId: 'inventory.site.organizationalStructure.concessionManagement.id',
  areaManagerId: 'inventory.site.organizationalStructure.areaManager.id',
  status: 'inventory.status',
  substatus: 'inventory.substatus',
  installationDate: 'inventory.installationDate',
  damaged: 'inventory.damaged',
  comments: 'inventory.comments',
  associatedEquipmentType: 'inventory.associatedEquipment.productType',
  associatedEquipmentSerialNumber: 'inventory.associatedEquipment.serialNumber',
  associatedEquipmentAdditionalKey: 'inventory.associatedEquipment.additionalKey',
  deviceTechnologyPopulate: 'inventory.deviceTechnologyPopulate',
  associatedMeters: 'inventory.associatedMeters',
  associatedMetersadditionalKey: 'inventory.associatedMeters.additionalKey',
  control: 'inventory.supplyPoint.control',
  distributionNetwork: 'inventory.supplyPoint.distributionNetwork',
  alias: 'inventory.supplyPoint.alias',
  ggcc: 'inventory.supplyPoint.ggcc',
  contractkey: 'inventory.supplyPoint.contract.key',
  connectionStatus: 'analytics.connectionStatus.status',
  lastConnection: 'analytics.connectionStatus.lastConnection',
  batteryStatus: 'analytics.battery.status',
  lastReadingDate: 'analytics.lastWaterVolume.date',
  lastReadingValue: 'analytics.lastWaterVolume.value',
  countCriticalMeters: 'analytics.countCriticalMeters',
  countActiveAlarms: 'analytics.countActiveAlarms',
  vFrames: 'analytics.frame.vFrames',
  vFramesAvg: 'analytics.frame.vFramesAvg',
  rFrames: 'analytics.frame.rFrames',
  eFrames: 'analytics.frame.eFrames',
  associatedSupplyPointDescription: 'inventory.flattenedSupplyPoints.description',
  associatedSupplyPointAlias: 'inventory.flattenedSupplyPoints.alias',
  associatedSupplyPointDistributionNetwork: 'inventory.flattenedSupplyPoints.distributionNetwork',
  associatedSupplyPointControl: 'inventory.flattenedSupplyPoints.control',
  associatedSupplyPointGGCC: 'inventory.flattenedSupplyPoints.ggcc',
  associatedSupplyPointContractKey: 'inventory.flattenedSupplyPoints.contractKey',
  associatedSupplyPointSector: 'inventory.flattenedSupplyPoints.sector',
  associatedSupplyPointSubsector: 'inventory.flattenedSupplyPoints.subsector',
  sector: 'inventory.supplyPoint.sector',
  subsector: 'inventory.supplyPoint.subsector'
};

export const productPathToShortKey = Object.entries(productShortKeyToPath).reduce(
  (acc, [dataKey, path]) => ({
    ...acc,
    [path]: dataKey
  }),
  {}
);
